<template>
  <service-layout type="workorder" :data="form.data" :init="form.init" @confirm="detail.confirm">
    <template #operation>
      <button-ajax v-if="form.data.id" type="primary" :method="operation.save.click">保存</button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'PD'" type="primary" :method="operation.return.click">退单</button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="workorder">工单信息</en-dropdown-item>
          <en-dropdown-item command="customer" :disabled="!store.accessRightsHash.CUSTOMER_EDIT || !store.accessRightsHash.VEHICLE_EDIT">
            客户档案
          </en-dropdown-item>
          <en-dropdown-item command="vehicle" :disabled="!store.accessRightsHash.VEHICLE_EDIT">车辆档案</en-dropdown-item>
          <en-dropdown-item command="rework-order">返修信息</en-dropdown-item>
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
          <en-dropdown-item command="maintain">查询保养数据</en-dropdown-item>
          <en-dropdown-item command="fake">
            {{ `附表(${form.data.fakeService && form.data.fakeService.id ? '已修改' : '未修改'})` }}
          </en-dropdown-item>
          <en-dropdown-item command="coupon">发送优惠券</en-dropdown-item>
          <en-dropdown-item command="mall">套餐购买</en-dropdown-item>
          <en-dropdown-item command="top-up">会员充值</en-dropdown-item>
          <en-dropdown-item command="deposit">定金缴纳</en-dropdown-item>
          <en-dropdown-item command="blance">调用余额</en-dropdown-item>
          <en-dropdown-item command="material">领料</en-dropdown-item>
          <en-dropdown-item command="discard">取消工单</en-dropdown-item>
          <en-dropdown-item command="qrcode" :disabled="!!form.data.customer?.wechatUnionId">
            {{ form.data.customer?.wechatUnionId ? '已绑定' : '微信绑定' }}
          </en-dropdown-item>
          <en-dropdown-item command="elecdoc" :disabled="form.data.status?.code !== 'PD'">
            {{ form.data.elecdocUploaded?.code === 'U' ? '已上传' : '上传' }}
          </en-dropdown-item>
        </template>
      </en-dropdown>
      <en-button v-if="form.data.fake?.value" @click="operation.back.click">返回</en-button>
    </template>

    <template #manifest>
      <table-manifest
        code="SVCSDBFD"
        :routes="[
          { path: '/service/business/workorder', name: '工单' },
          { path: '/service/business/quotation', name: '估价单' },
          { path: '/service/business/inspection', name: '检测单' }
        ]"
        :ajax="{ action: 'GET /enocloud/service/query' }"
        :props="{ start: 'enterStartDate', end: 'enterEndDate' }"
        :table-config="manifest.config"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
        :ref="setRef('mainfest')"
      >
        <template #PLATE_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceQueryDto'] }">
          <span>{{ row.plateNo }}</span>
        </template>

        <template #VEHICLE_SPEC="{ row }: { row: EnocloudServiceDefinitions['ServiceQueryDto'] }">
          {{ row.vehicleSpec?.split('^').join(',') }}
        </template>

        <template #STATUS="{ row }: { row: EnocloudServiceDefinitions['ServiceQueryDto'] }">
          <div class="flex gap-2">
            <en-tag :type="row.status?.message === '已结算' ? 'success' : ''">{{ row.status?.message }}</en-tag>
            <en-tag size="small">工</en-tag>
            <en-tag size="small" :type="row.vehicleServiceGroup?.serviceQuotationId ? 'success' : 'info'" @click.stop="manifest.quotation.click(row)">
              估
            </en-tag>
            <en-tag
              size="small"
              :type="row.vehicleServiceGroup?.vehicleInspectionId ? 'success' : 'info'"
              @click.stop="manifest.inspection.click(row)"
            >
              检
            </en-tag>
          </div>
        </template>

        <template #form="{ data }">
          <en-form-item label="车牌">
            <en-input v-model="data.plateNo"></en-input>
          </en-form-item>
          <en-form-item label="工单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="工单状态">
            <select-maintain
              v-model="data.statusCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SVCSTAT']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="服务顾问">
            <select-maintain
              v-model="data.advisorId"
              :ajax="{
                action: 'GET /enocloud/common/advisorteam/advisor'
              }"
              :props="{ label: 'user.name', value: 'user.id' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="车主">
            <en-input v-model="data.customerName"></en-input>
          </en-form-item>
          <en-form-item label="手机号">
            <en-input v-model="data.customerCellphone"></en-input>
          </en-form-item>

          <en-form-item label="车品牌">
            <en-input v-model="data.vehicleSpec"></en-input>
          </en-form-item>
          <en-form-item label="完工日期">
            <en-date-picker v-model:start="data.completionStartDate" v-model:end="data.completionEndDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="进厂日期">
            <en-date-picker v-model:start="data.enterEndDate" v-model:end="data.enterStartDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="结算日期">
            <en-date-picker v-model:start="data.settlementStartDate" v-model:end="data.settlementEndDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="维修类别">
            <select-maintain
              v-model="data.serviceCategoryName"
              :ajax="{ action: 'GET /enocloud/common/service/category', params: (params, value) => (params.payload = { name: value }) }"
              :props="{ label: 'name', value: 'name' }"
              value-key="id"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="收款状态">
            <select-maintain
              v-model="data.settlementProgress"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STLMTPRG']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="维修项目">
            <select-maintain
              v-model="data.maintenance"
              :ajax="{ action: 'GET /enocloud/common/maintenance', params: (params, value) => (params.payload = { name: value }) }"
              :props="{ label: 'name', value: 'name' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="编码">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="VIN码">
            <en-input v-model="data.vin"></en-input>
          </en-form-item>
          <en-form-item label="发动机号">
            <en-input v-model="data.engineNumber"></en-input>
          </en-form-item>
          <en-form-item label="客户来源">
            <select-maintain
              v-model="data.salesmanType"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CLKTYPE']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="介绍人">
            <select-maintain
              v-model="data.salesmanId"
              :ajax="{ action: 'GET /enocloud/common/clerk', params: (params, value) => (params.payload = { code: data.salesmanType, name: value }) }"
              :disabled="data.salesmanType === '' || data.salesmanType === 'N'"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              lazy
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="是否开票">
            <select-maintain
              v-model="data.invoiced"
              :props="{ label: 'message', value: 'code' }"
              :options="[
                { message: '是', code: 'Y' },
                { message: '否', code: 'N' }
              ]"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="客户分类">
            <select-maintain
              v-model="data.customerCategoryId"
              :ajax="{ action: 'GET /enocloud/common/customer/category', params: (params, value) => (params.payload = { name: value }) }"
              :props="{ label: 'name', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="工单类别">
            <select-maintain
              v-model="data.quickCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
              :props="{ label: (option: EnocloudCommonDefinitions['LookupDto']) => (option.code === 'Y' ? '快速工单' : '普通工单'), value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="送修人">
            <en-input v-model="data.sender"></en-input>
          </en-form-item>
          <en-form-item label="送修电话">
            <en-input v-model="data.senderTelephone"></en-input>
          </en-form-item>
          <en-form-item label="接车备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
          <en-form-item label="结算备注">
            <en-input v-model="data.settlementComment"></en-input>
          </en-form-item>
          <en-form-item label="结算员">
            <select-maintain
              v-model="data.settlementById"
              :ajax="{ action: 'GET /enocloud/common/user', params: (params, value) => (params.payload = { name: value }) }"
              :props="{ label: 'name', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
      <div class="flex gap-4">
        <span>业务选择：{{ form.data.fake?.value ? '附表' : '' }}工单</span>
        <span>维修类别：{{ form.data.serviceCategory?.name }}</span>
      </div>

      <en-collapse>
        <en-collapse-item>
          <template #title>
            <div class="flex items-center gap-6">
              <span>{{ form.data.vehicle?.plateNo }}</span>
              <span>{{ form.data.customer?.name }}/{{ form.data.customer?.cellphone }}</span>
              <span>单号：{{ form.data.serialNo }}</span>
              <en-tag :type="form.data.status?.message === '已结算' ? 'success' : ''">{{ form.data.status?.message }}</en-tag>
              <span>{{ form.data.vehicle?.vehicleSpec.join('/') }}</span>
              <span class="text-red">欠款{{ formatMoney(form.data?.customer?.accountInfo?.debt) }}</span>
              <span>结算台次：{{ form.data?.vehicle?.consumeCount }}</span>
            </div>
          </template>
          <div class="grid grid-cols-4 gap-x-6">
            <span>VIN码：{{ form.data.vehicle?.vin }}</span>
            <span>服务顾问：{{ form.data.advisor?.name }}</span>
            <span>工单类别：{{ form.data.quick?.value ? '快速工单' : '普通工单' }}</span>
            <span>客户分类：{{ form.data.customer?.category?.name }}</span>
            <span>发动机号：{{ form.data.vehicle?.engineNumber }}</span>
            <span>工单介绍人： {{ form.data.salesman?.name }}</span>
            <span>进厂日期： {{ formatDate(form.data.enterDatetime, true) }}</span>
            <span>变速箱： {{ form.data.vehicle?.gearboxType?.message }}</span>
            <span>送修人：{{ `${form.data.sender}/${form.data.senderTelephone}` }}</span>
            <span>预计完工： {{ formatDate(form.data.expectedCompletionDatetime, true) }}</span>
          </div>
          <span>接车备注： {{ form.data.comment }}</span>
        </en-collapse-item>
      </en-collapse>

      <div class="flex items-center gap-6 text-xs text-neutral-400">
        <div class="flex flex-col">
          <span class="text-black">维修总费用</span>

          <span class="bg-primary text-white p-1">{{ formatMoney(form.serviceFee.amount) }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <span>工时费</span>
          <span>{{ formatMoney(form.maintenanceFee.amountBeforeDiscount) }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <span>配件费</span>
          <span>{{ formatMoney(form.goodsFee.amountBeforeDiscount) }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="cursor-pointer flex items-center justify-between hover:text-primary" @click="form.fee.management.click">
            <span>配件管理费</span>
            <icon-edit class="w-3 h-3"></icon-edit>
          </div>
          <span>{{ formatMoney(form.serviceFee.managementFee) }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="cursor-pointer flex items-center justify-between hover:text-primary" @click="form.fee.tax.click">
            <span>税金</span>
            <icon-edit class="w-3 h-3"></icon-edit>
          </div>
          <span>{{ formatMoney(form.serviceFee.tax) }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="cursor-pointer flex items-center justify-between hover:text-primary" @click="form.fee.other.click">
            <span>其他费用</span>
            <icon-edit class="w-3 h-3"></icon-edit>
          </div>
          <span>{{ formatMoney(form.serviceFee.otherAmount) }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="cursor-pointer flex items-center justify-between hover:text-primary" @click="form.fee.expense.click">
            <span>业务支出</span>
            <icon-edit class="w-3 h-3"></icon-edit>
          </div>
          <span>{{ formatMoney(form.serviceFee.expenseAmount) }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <span>实际产值</span>
          <span>{{ formatMoney(form.serviceFee.actualOutput) }}</span>
        </div>
      </div>
    </en-card>

    <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <template #operation>
              <button-ajax type="primary" :disabled="form.disabled || !store.accessRightsHash.SERVICE_SETTLE" :method="tabs.settlement.click">
                结算
              </button-ajax>
            </template>

            <en-tab-pane label="检查" name="basic" :badge="form.checkPercent">
              <en-scrollbar :height="height - 55">
                <en-form :model="form.data" class="grid grid-cols-3 gap-x-6">
                  <en-form-item>
                    <template #label>
                      <div class="grid grid-cols-[auto_1fr_auto] items-center gap-2">
                        <span>进厂里程</span>
                        <span class="text-xs text-primary">上次进厂里程：{{ form.data.lastTimeService?.currentMileage }}公里</span>
                        <span class="text-xs text-primary">{{ formatDate(form.data.lastTimeService?.enterDatetime, true) || '无' }}</span>
                      </div>
                    </template>
                    <en-input-number
                      v-model="form.data.currentMileage"
                      :min="0"
                      suffix="公里"
                      :disabled="form.disabled"
                      class="w-full"
                      @blur="form.currentMileage.blur"
                    ></en-input-number>
                  </en-form-item>
                  <en-form-item label="进厂油量">
                    <select-maintain
                      v-model="form.data.remainingOil"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['RMGOIL']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="是否保养">
                    <select-maintain
                      v-model="form.data.maintenanceFlag"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                      :disabled="form.disabled"
                      class="w-full"
                      @change="form.maintenanceFlag.change"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item>
                    <template #label>
                      <div class="grid grid-cols-[auto_1fr_auto] items-center gap-2">
                        <span>下次保养里程</span>
                        <span class="text-xs text-primary">上次保养里程：{{ form.data.lastTimeMaintainService?.currentMileage }}公里</span>
                        <span class="text-xs text-primary">{{ formatDate(form.data.lastTimeMaintainService?.enterDatetime, true) || '无' }}</span>
                      </div>
                    </template>
                    <div class="flex gap-6">
                      <en-input-number
                        v-if="form.data.maintenanceFlag?.value"
                        :model-value="form.data.vehicle?.nextMaintenanceMileage"
                        :min="0"
                        :disabled="form.disabled"
                        class="w-full"
                        @change="form.nextMaintenanceMileage.change"
                      ></en-input-number>
                      <en-input-number
                        v-else
                        v-model="form.data.nextMaintenanceMileage"
                        :disabled="form.disabled"
                        suffix="公里"
                        class="w-full"
                        @change="form.nextMaintenanceMileage.change"
                      ></en-input-number>
                      <select-maintain
                        v-model="form.nextMaintenanceMileage.value"
                        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['MILEAGE']) }"
                        :props="{ label: 'name', value: 'name' }"
                        placeholder="请选择里程"
                        :disabled="form.disabled"
                        @change="form.nextMaintenanceMileage.select"
                      ></select-maintain>
                    </div>
                  </en-form-item>
                  <en-form-item>
                    <template #label>
                      <div class="grid grid-cols-[auto_1fr_auto] items-center gap-2">
                        <span>下次保养日期</span>
                      </div>
                    </template>
                    <en-date-picker v-model="form.data.nextMaintenanceDate" :disabled="form.disabled" class="w-full"></en-date-picker>
                  </en-form-item>
                  <en-form-item label="旧件处理">
                    <select-maintain
                      v-model="form.data.oldParts"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['ODPSPRC']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="随车物品">
                    <select-maintain
                      v-model="form.data.things"
                      :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['THSINCAR']) }"
                      :props="{ label: 'name', value: 'name' }"
                      placeholder="填写随车物品"
                      allow-create
                      multiple
                      collapseTags
                      collapseTagsPanel
                      :disabled="form.disabled"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="故障描述">
                    <select-maintain
                      v-model="form.data.descriptions"
                      :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['SERVDESC']) }"
                      :props="{ label: 'name', value: 'name' }"
                      allow-create
                      placeholder="请选择故障描述"
                      multiple
                      collapseTags
                      collapseTagsPanel
                      default-first-option
                      :disabled="form.disabled"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="故障原因">
                    <select-maintain
                      v-model="form.data.solution"
                      :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['SERVSOLU']) }"
                      :props="{ label: 'name', value: 'name' }"
                      allow-create
                      placeholder="请选择故障原因"
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="接车备注">
                    <en-input v-model="form.data.comment" placeholder="请填写接车备注" :disabled="form.disabled"></en-input>
                  </en-form-item>
                  <en-form-item label="上次建议">
                    <en-input :model-value="form.data.lastTimeService?.suggestions" disabled></en-input>
                  </en-form-item>
                  <en-form-item label="图片/视频添加" class="col-span-3">
                    <upload-maintain
                      v-model="form.data.serviceVehicleImgUrls"
                      url-key="vehicleImgUrl"
                      multiple
                      :disabled="form.disabled"
                      dir="serviceVehicleImgUrls"
                    ></upload-maintain>
                  </en-form-item>
                  <en-form-item label="外观标注" :span="3">
                    <damage-identify v-model="form.data.damages" :disabled="form.disabled"></damage-identify>
                  </en-form-item>
                </en-form>
              </en-scrollbar>
            </en-tab-pane>

            <en-tab-pane
              label="项目"
              name="maintenance"
              :badge="form.data.maintenances?.length"
              :style="{ height: height - 55 + 'px', overflow: 'auto' }"
              class="gap-6"
            >
              <div class="flex gap-4">
                <select-maintain
                  :ajax="{
                    action: 'GET /enocloud/common/maintenance',
                    params: (params, value) => (params.payload = { quickSearch: value, ignoreSpraySurface: true })
                  }"
                  :props="{
                    label: 'name',
                    value: '',
                    disabled: (option: EnocloudCommonDefinitions['MaintenanceDto']) =>
                      Boolean(form.data.maintenances?.find((item) => item.maintenance?.id === option.id))
                  }"
                  value-key="id"
                  placeholder="请选择维修项目"
                  remote
                  :disabled="form.disabled"
                  class="w-110"
                  @change="form.maintenances.operation.change"
                ></select-maintain>
                <en-button
                  type="primary"
                  :disabled="form.disabled || !store.accessRightsHash.SERVICE_MAINTENANCE_CUSTOM"
                  @click="form.maintenances.operation.add.click"
                >
                  新增项目
                </en-button>
                <en-button type="primary" :disabled="form.disabled" @click="form.maintenances.operation.inflated.click">加大</en-button>
                <en-button type="primary" :disabled="form.disabled" @click="form.maintenances.operation.spray.click">喷漆</en-button>
                <en-dropdown :disabled="form.disabled" @command="form.maintenances.operation.command">
                  <en-button>操作</en-button>
                  <template #dropdown>
                    <en-dropdown-item command="chargingMethod">批量设置收费类别</en-dropdown-item>
                    <en-dropdown-item command="assignee">批量派工</en-dropdown-item>
                  </template>
                </en-dropdown>
                <en-checkbox :disabled="form.disabled">喷涂项目合并打印</en-checkbox>
              </div>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    :ref="setRef('formMaintenancesTable')"
                    code="SVCMTFLD"
                    :data="form.data.maintenances"
                    :height="height"
                    show-selectable
                    show-summary
                    :summary-method="form.maintenances.summaryMethod"
                    @selection-change="form.maintenances.selection.change"
                  >
                    <template #OPERATION="{ row, $index }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto']; $index: number }">
                      <en-button type="primary" :disabled="form.disabled" link @click="form.maintenances.operation.delete.click($index)">
                        删除
                      </en-button>
                      <en-button type="primary" :disabled="form.disabled" link @click="form.maintenances.operation.edit.click(row)"> 编辑 </en-button>
                      <en-button
                        v-if="row.chargingMethod?.code === 'SBT'"
                        type="primary"
                        :disabled="form.disabled"
                        link
                        @click="form.maintenances.operation.outsource.click(row)"
                      >
                        外包信息
                      </en-button>
                    </template>

                    <template #NAME="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <span v-if="form.disabled || row.chargingMethod?.code === 'RWK' || row.maintenance?.spraySurface">{{ row.name }}</span>
                      <en-input v-else-if="!row.maintenance?.id || row.inflatedFlag?.code === 'Y'" v-model="row.name"></en-input>
                      <select-maintain
                        v-else
                        v-model="row.maintenance"
                        :ajax="{
                          action: 'GET /enocloud/common/maintenance',
                          params: (params, value) => (params.payload = { quickSearch: value, ignoreSpraySurface: true })
                        }"
                        :props="{ label: 'name', value: '' }"
                        remote
                        value-key="id"
                        :disabled="form.disabled"
                      ></select-maintain>
                    </template>

                    <template #CHARGING_METHOD="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <span
                        v-if="
                          form.disabled ||
                          row.chargingMethod?.code === 'RWK' ||
                          form.hasFullCarPaints ||
                          !store.accessRightsHash.SERVICE_CHARGING_METHOD_UPDATE
                        "
                      >
                        {{ row.chargingMethod?.message }}
                      </span>
                      <select-maintain
                        v-else
                        v-model="row.chargingMethod"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => (params.paths = ['CHGMTD']),
                          convert: (data) => data.filter((item) => item.forServiceMaintenance)
                        }"
                        :props="{ label: 'message', value: '', disabled: (option: any) => option.code === 'RWK' }"
                        value-key="code"
                        @change="form.maintenances.chargingMethod.change(row)"
                      ></select-maintain>
                    </template>

                    <template #LABOR_HOUR="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <en-input-number
                        v-model="row.laborHour"
                        :disabled="
                          form.disabled ||
                          row.valuationMethod?.code === 'P' ||
                          (row.id && store.attribute.FCMTNCS.value === 'Y' && !store.accessRightsHash.SERVICE_MAINTENANCE_PRICE_EDIT)
                        "
                        :precision="2"
                        @change="form.maintenances.laborHour.change(row)"
                        class="w-full"
                      ></en-input-number>
                    </template>

                    <template #INTERNAL_LABOR_HOUR="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <en-input-number v-model="row.internalLaborHour" :disabled="form.disabled" :precision="2" class="w-full"></en-input-number>
                    </template>

                    <template #PRICE="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <en-input-number
                        v-model="row.price"
                        @change="form.maintenances.price.change(row)"
                        :disabled="
                          form.disabled ||
                          row.chargingMethod?.code === 'RWK' ||
                          (row.id && store.attribute.FCMTNCS.value === 'Y' && !store.accessRightsHash.SERVICE_MAINTENANCE_PRICE_EDIT)
                        "
                        remote
                        multiple
                        class="w-full"
                      ></en-input-number>
                    </template>

                    <template #VALUATION_METHOD="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <select-maintain
                        v-model="row.valuationMethod"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => (params.paths = ['VLMTD'])
                        }"
                        :props="{ label: 'message', value: '' }"
                        value-key="code"
                        :disabled="form.disabled"
                        @change="form.maintenances.valuationMethod.change(row)"
                      ></select-maintain>
                    </template>

                    <template #WORKING_TEAM="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <select-maintain
                        v-if="form.data.branch?.id"
                        v-model="row.workingTeam"
                        :ajax="{
                          action: 'GET /enocloud/common/workingteam',
                          params: (params) => (params.payload = { branchIds: [Number(form.data.branch?.id)], statusCode: 'A' })
                        }"
                        :props="{ label: 'name', value: '' }"
                        value-key="id"
                        :disabled="form.disabled"
                        @change="form.maintenances.workingTeam.change(row)"
                      ></select-maintain>
                    </template>

                    <template #ASSIGNEES="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <select-maintain
                        :model-value="row.assignees.map((item) => item.assignee || item.name)"
                        :options="row.workingTeam?.members"
                        :props="{ label: 'user.name', value: 'user' }"
                        allow-create
                        multiple
                        value-key="id"
                        :disabled="form.disabled || row.chargingMethod?.code === 'RMN' || row.chargingMethod?.code === 'TNM'"
                        collapse-tags
                        collapse-tags-tooltip
                        class="w-full"
                        @change="form.maintenances.assignees.change(row, $event as Array<string | EnocloudServiceDefinitions['UserDto']>)"
                      ></select-maintain>
                    </template>

                    <template #INFLATED_FLAG="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <span v-if="row.inflatedFlag?.inflated">X</span>
                      <select-maintain
                        v-else
                        v-model="row.inflatedFlag"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => (params.paths = ['INFLTTPE']),
                          convert: (data) => data.filter((item) => item.code === 'Z')
                        }"
                        :props="{ label: 'message', value: '' }"
                        :disabled="form.disabled"
                        value-key="code"
                      ></select-maintain>
                    </template>

                    <template #COMMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <en-input v-model="row.comment"></en-input>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane
              label="配件"
              name="goods"
              :badge="form.data.goods?.length"
              :style="{ height: height - 55 + 'px', overflow: 'auto' }"
              class="gap-6"
            >
              <div class="flex gap-4">
                <select-maintain
                  :ajax="{
                    action: 'GET /enocloud/common/goods',
                    params: (params, value) =>
                      (params.payload = {
                        quickSearch: value,
                        branchId: form.data.branch?.id,
                        statusCode: 'A',
                        rsexp: 'id,name,serialNo,primaryVehicleSpec,comment,batches[*],specifications[id,name,goodsSpecificationAddition[*]]'
                      })
                  }"
                  :props="{ label: 'name', value: '' }"
                  value-key="id"
                  placeholder="请选择维修配件"
                  remote
                  :disabled="form.disabled"
                  class="w-110"
                  @change="form.goods.operation.change"
                >
                  <template #default="{ option }: { option: EnocloudCommonDefinitions['GoodsDto'] }">
                    <div class="flex justify-between items-center gap-5">
                      <span>{{ option.name }}</span>
                      <span class="text-xs text-gray-500">
                        {{ option.serialNo }}
                        {{ option.primaryVehicleSpec?.join(',') }}
                        库存({{ option.batches.reduce((count, batch) => count + (batch.count ?? 0), 0) }})
                      </span>
                    </div>
                  </template>
                </select-maintain>
                <en-button type="primary" :disabled="form.disabled" @click="form.goods.operation.add.click">新增配件</en-button>
                <en-button type="primary" :disabled="form.disabled" @click="form.goods.operation.inflated.click">加大</en-button>
                <en-button type="primary" :disabled="form.disabled" @click="form.goods.operation.batch.click">批量添加配件</en-button>
                <en-checkbox :disabled="form.disabled">显示全部</en-checkbox>
                <en-checkbox :disabled="form.disabled">相同配件合并打印</en-checkbox>
              </div>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SMTGDFLD"
                    :data="form.data.goods"
                    :height="height"
                    show-selectable
                    show-summary
                    @selection-change="form.goods.selection.change"
                    :config="{
                      PRIME_COST: {
                        visible: () => store.accessRightsHash.GOODS_BATCH_QUERY && store.accessRightsHash.PRICE_PURCHASE
                      },
                      COST: {
                        visible: () => store.accessRightsHash.GOODS_BATCH_QUERY && store.accessRightsHash.PRICE_PURCHASE
                      }
                    }"
                  >
                    <template #OPERATION="{ $index }: { $index: number }">
                      <en-button type="primary" link :disabled="form.disabled" @click="form.goods.operation.delete.click($index)">删除</en-button>
                    </template>

                    <template #OEM="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input v-if="row.inflatedFlag?.inflated && !row.id" v-model="row.oem" :disabled="form.disabled"></en-input>
                      <span v-else-if="row.inflatedFlag?.inflated && !!row.id">{{ row.oem }}</span>
                      <span v-else>{{ row.goodsSpecification?.goods?.oem }}</span>
                    </template>

                    <template #NAME="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input v-if="row.inflatedFlag?.inflated && !row.id" v-model="row.name" :disabled="form.disabled"></en-input>
                      <span v-else-if="row.inflatedFlag?.inflated && !!row.id">{{ row.name }}</span>
                      <span v-else>{{ row.goodsSpecification?.goods?.name }}</span>
                    </template>

                    <template #SERIAL_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input v-if="row.inflatedFlag?.inflated && !row.id" v-model="row.serialNo" :disabled="form.disabled"></en-input>
                      <span v-else-if="row.inflatedFlag?.inflated && !!row.id">{{ row.serialNo }}</span>
                      <span v-else-if="row.count">{{ row.goodsSpecification?.goods?.serialNo }}</span>
                      <select-maintain
                        v-else
                        v-model="row.goods"
                        :disabled="form.disabled || row.returned?.value"
                        :ajax="{
                          action: 'GET /enocloud/common/goods',
                          params: (params, value) => {
                            params.payload = {
                              quickSearch: value,
                              branchId: form.data.branch?.id,
                              statusCode: 'A',
                              rsexp: 'id,name,serialNo,primaryVehicleSpec,comment,batches[*],specifications[id,name,goodsSpecificationAddition[*]]'
                            }
                          }
                        }"
                        :props="{ label: 'serialNo', value: '' }"
                        remote
                        value-key="id"
                        @change="form.goods.serialNo.change(row, $event as EnocloudServiceDefinitions['GoodsDto'])"
                      >
                        <template #default="{ option }: { option: EnocloudServiceDefinitions['GoodsDto'] }">
                          <div class="flex items-center justify-between gap-5">
                            <span>{{ option.name }}</span>
                            <div class="flex text-xs text-[#8492a6]">
                              <span>{{ option.serialNo }}</span>
                              <span>{{ option.primaryVehicleSpec?.join(',') }}</span>
                              <span>{{ option.comment }}</span>
                              <span>库存:({{ (option.batches ?? []).reduce((c, i) => calculator.add(c, i.count), 0) }})</span>
                            </div>
                          </div>
                        </template>
                      </select-maintain>
                    </template>

                    <template #EXPECTED_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input-number
                        v-model="row.planCount"
                        :min="0"
                        :precision="2"
                        :disabled="form.disabled || !!row.returnedServiceGoods?.id"
                        class="w-full"
                      ></en-input-number>
                    </template>

                    <template #ACTUAL_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input-number
                        v-if="row.inflatedFlag?.inflated"
                        v-model="row.count"
                        :precision="2"
                        :disabled="form.disabled"
                        @change="form.goods.count.change(row)"
                        class="w-full"
                      ></en-input-number>
                      <template v-else>
                        <en-tooltip placement="top" :disabled="!row.count || !row.histories.length">
                          <template #content>
                            <span v-for="item of [...new Set(row.histories.map((h) => h.inventoryHistory?.type?.code))]">
                              <span>{{ form.goods.count.tooltip(row, item) }}</span>
                            </span>
                          </template>
                          <span>{{ row.count }}</span>
                        </en-tooltip>
                      </template>
                    </template>

                    <template #PRICE="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input-number
                        v-if="row.inflatedFlag?.inflated"
                        v-model="row.price"
                        :precision="2"
                        remote
                        multiple
                        :disabled="form.disabled || row.returned?.value"
                        @change="form.goods.price.change(row)"
                        class="w-full"
                      ></en-input-number>
                      <template v-else>
                        <select-maintain
                          v-if="row.goodsSpecification?.id"
                          v-model="row.price"
                          :ajax="{
                            action: 'GET /enocloud/common/goods/specification/:specificationId/price',
                            params: (params) => {
                              params.paths = [row.goodsSpecification?.id]
                            }
                          }"
                          :props="{ label: 'price', value: 'price' }"
                          :disabled="form.disabled || row.returned?.value"
                          allow-create
                          @change="form.goods.price.change(row)"
                        >
                          <template #default="{ option }">
                            <span style="float: left">{{ formatMoney(option.price) }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ option.type.message }}</span>
                          </template>
                        </select-maintain>
                        <span v-else>{{ formatMoney(row.price) }}</span>
                      </template>
                    </template>

                    <template #CHARGING_METHOD="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <span v-if="form.disabled || (row.id && row.inflatedFlag?.inflated) || !store.accessRightsHash.SERVICE_CHARGING_METHOD_UPDATE">
                        {{ row.chargingMethod?.message }}
                      </span>
                      <select-maintain
                        v-else
                        v-model="row.chargingMethod"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => {
                            params.paths = ['CHGMTD']
                            params.payload = { excludes: ['TNM', 'RMN'] }
                          }
                        }"
                        :props="{
                          label: 'message',
                          value: '',
                          disabled: (option: EnocloudCommonDefinitions['LookupDto']) => option.code === 'OTS' && !row.inflatedFlag?.inflated
                        }"
                        value-key="code"
                        @change="form.goods.chargingMethod.change"
                      ></select-maintain>
                    </template>

                    <template #INVENTORY="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <span :style="{ color: (row.inventoryCount || 0) <= 0 ? 'red' : '' }">
                        {{ row.inflatedFlag?.inflated ? row.inventoryCount : `${row.inventoryCount}${row.goodsSpecification?.goods?.warehouseUnit}` }}
                      </span>
                    </template>

                    <template #SPECIFICATION="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input v-if="row.inflatedFlag?.inflated && !row.id" v-model="row.inflatedUnit"></en-input>
                      <span v-else-if="row.inflatedFlag?.inflated && !!row.id">{{ row.inflatedUnit }}</span>
                      <select-maintain
                        v-else
                        :model-value="row.goodsSpecification"
                        :disabled="form.disabled || row.count > 0 || !!row.returnedServiceGoods?.id"
                        :options="row.goodsSpecification?.goods?.specifications"
                        :props="{ label: 'name', value: '' }"
                        value-key="id"
                        @change="form.goods.specification.change(row, $event as EnocloudServiceDefinitions['GoodsSpecificationDto'])"
                      ></select-maintain>
                    </template>

                    <template #VEHICLE_SPEC="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <span>{{ row.goodsSpecification?.goods?.primaryVehicleSpec?.join(',') }}</span>
                    </template>

                    <template #USAGE_COMMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <en-input v-model="row.comment"></en-input>
                    </template>

                    <template #GOODS_COMMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <span>{{ row.goodsSpecification?.goods?.comment }}</span>
                    </template>

                    <template #BATCH_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <span>{{ row.inventory?.batchNo }}</span>
                    </template>

                    <template #BRAND="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <span>{{ row.goodsSpecification?.goods?.brand }}</span>
                    </template>

                    <template #INFLATED="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                      <span v-if="row.inflatedFlag?.inflated">X</span>
                      <select-maintain
                        v-else
                        v-model="row.inflatedFlag"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => (params.paths = ['INFLTTPE']),
                          convert: (data) => data.filter((item) => item.code === 'Z')
                        }"
                        :props="{ label: 'message', value: '' }"
                        value-key="code"
                      ></select-maintain>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="检验" name="inspection" badge="未完成" :colorClass="'text-yellow-400'">
              <en-scrollbar :height="height - 55">
                <div class="flex gap-6">
                  <span>车辆检查</span>
                  <en-button type="primary" @click="form.inspection.collapse = !form.inspection.collapse">
                    {{ form.inspection.collapse ? '展开' : '收起' }}
                  </en-button>
                </div>

                <en-table v-show="!form.inspection.collapse" :data="form.data.maintenances">
                  <en-table-column type="index" width="50"></en-table-column>
                  <en-table-column label="项目名称" prop="name"></en-table-column>
                  <en-table-column label="项目备注" prop="comment"></en-table-column>
                  <en-table-column label="施工人员">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <span>{{ row.workingTeam?.name }}</span>
                      <span>{{ row.assignees.map((item) => (item.assignee ? item.assignee.name : item.name)).join(',') }}</span>
                    </template>
                  </en-table-column>
                  <en-table-column label="返工记录">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <en-button type="primary" text :disabled="!row.reworks?.length" @click="form.inspection.reworks.click(row)">
                        {{ row.reworks?.length }}
                      </en-button>
                    </template>
                  </en-table-column>
                  <en-table-column label="操作">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
                      <en-button type="primary" text @click="form.inspection.rework.click(row)">返工</en-button>
                    </template>
                  </en-table-column>
                </en-table>

                <en-form class="grid grid-cols-3 gap-x-6">
                  <en-form-item label="终检人">
                    <select-maintain
                      v-model="form.data.qualityInspector"
                      :ajax="{
                        action: 'GET /enocloud/common/finalinspector',
                        convert: (data) => data.filter((item) => item.inspector?.status?.code !== 'Y')
                      }"
                      :props="{ label: 'inspector.name', value: 'inspector' }"
                      value-key="id"
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="进厂里程">
                    <en-input-number
                      v-model="form.data.currentMileage"
                      :min="0"
                      suffix="公里"
                      :disabled="form.disabled"
                      class="w-full"
                    ></en-input-number>
                  </en-form-item>
                  <en-form-item label="质保里程">
                    <div class="flex gap-6">
                      <en-input-number
                        v-model="form.data.currentMileage"
                        :min="0"
                        suffix="公里"
                        :disabled="form.disabled"
                        class="w-full"
                      ></en-input-number>
                      <select-maintain
                        v-model="form.data.currentMileage"
                        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['MILEAGE']) }"
                        placeholder="请选择里程"
                        :disabled="form.disabled"
                        :props="{ label: 'name', value: (option: EnocloudCommonDefinitions['HintDto']) => parseFloat(option.name ?? '') }"
                      ></select-maintain>
                    </div>
                  </en-form-item>
                  <en-form-item label="质保时间">
                    <div class="flex gap-6">
                      <en-date-picker v-model="form.data.warrantyDate"></en-date-picker>
                      <select-maintain
                        v-model="form.warrantyDate.hint.value"
                        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['MONTH']) }"
                        :props="{ label: 'name', value: 'name' }"
                        :disabled="!form.data.warrantyDate"
                        @change="form.warrantyDate.hint.change"
                      ></select-maintain>
                    </div>
                  </en-form-item>
                  <en-form-item label="下次保养日期">
                    <en-date-picker v-model="form.data.nextMaintenanceDate" :disabled="form.disabled" class="w-full"></en-date-picker>
                  </en-form-item>
                  <en-form-item label="下次保养里程">
                    <div class="flex gap-6">
                      <en-input-number
                        v-if="form.data.maintenanceFlag?.code === 'N' && form.data.vehicle?.nextMaintenanceMileage"
                        v-model="form.data.vehicle.nextMaintenanceMileage"
                        :min="0"
                        class="w-full"
                      ></en-input-number>
                      <en-input-number v-else v-model="form.data.nextMaintenanceMileage" suffix="公里" class="w-full"></en-input-number>
                      <select-maintain
                        v-model="form.nextMaintenanceMileage.value"
                        :options="[
                          { label: '+5000', value: 5000 },
                          { label: '+7000', value: 7000 },
                          { label: '+10000', value: 10000 }
                        ]"
                        @change="form.nextMaintenanceMileage.select"
                      ></select-maintain>
                    </div>
                  </en-form-item>
                  <en-form-item label="质检日期">
                    <en-date-picker
                      v-model="form.data.qualityDatetime"
                      type="datetime"
                      value-format="YYYY-MM-DDThh:mm:ss"
                      :disabled="form.disabled"
                      class="w-full"
                    ></en-date-picker>
                  </en-form-item>
                  <en-form-item v-if="form.suggestions.show" label="维修建议" :required="form.suggestions.required">
                    <en-input v-model="form.data.suggestions" type="textarea" :disabled="form.disabled"></en-input>
                  </en-form-item>
                  <en-form-item label="接车备注">
                    <en-input v-model="form.data.comment" type="textarea" :disabled="form.disabled"></en-input>
                  </en-form-item>
                  <en-form-item label="图片/视频添加" class="col-span-3">
                    <upload-maintain v-model="form.data.inspectImgUrl" multiple watermark dir="inspectImgUrls"></upload-maintain>
                  </en-form-item>
                </en-form>
              </en-scrollbar>
            </en-tab-pane>

            <en-tab-pane label="结算" name="settlement" badge="未完成" :colorClass="'text-yellow-400'">
              <en-scrollbar :height="height - 55" view-class="flex flex-col gap-6">
                <div>
                  <en-button type="primary" @click="form.discount.click('A')">整单折扣</en-button>
                  <en-button type="primary" @click="form.discount.click('S')">单项折扣</en-button>
                  <en-button type="primary" @click="form.coupon.click">可用卡券</en-button>
                </div>

                <en-form label-position="right" label-width="120" label-suffix="：" class="pt-6 bg-gray-100 grid grid-cols-5">
                  <en-form-item label="工时费">{{ formatMoney(form.maintenanceFee.amountBeforeDiscount) }}</en-form-item>
                  <en-form-item label="工时折扣率">
                    {{
                      formatPercent(store.attribute.SVCDISRSH.value === 'P' ? form.maintenanceFee.discountRate : 1 - form.maintenanceFee.discountRate)
                    }}
                  </en-form-item>
                  <en-form-item label="折扣额">{{ formatMoney(form.maintenanceFee.discountAmount) }}</en-form-item>
                  <en-form-item label="配件费" class="col-start-1">{{ formatMoney(form.goodsFee.amountBeforeDiscount) }}</en-form-item>
                  <en-form-item label="配件折扣率">
                    {{ formatPercent(store.attribute.SVCDISRSH.value === 'P' ? form.goodsFee.discountRate : 1 - form.goodsFee.discountRate) }}
                  </en-form-item>
                  <en-form-item label="折扣额">{{ formatMoney(form.goodsFee.discountAmount) }}</en-form-item>
                  <en-form-item label="配件管理费率">{{ formatPercent(form.serviceFee.managementFeeRate) }}</en-form-item>
                  <en-form-item label="配件管理费">{{ formatMoney(form.serviceFee.managementFee) }}</en-form-item>
                  <en-form-item label="税金比率">{{ formatPercent(form.serviceFee.taxRate) }}</en-form-item>
                  <en-form-item label="税金">{{ formatMoney(form.serviceFee.tax) }}</en-form-item>
                  <en-form-item label="其他费用">{{ formatMoney(form.serviceFee.otherAmount) }}</en-form-item>
                  <en-form-item label="卡券折扣">{{ formatMoney(form.serviceFee.couponInstancesAmount) }}</en-form-item>
                  <en-form-item label="折扣优惠">
                    {{ formatMoney(calculator.add(form.maintenanceFee.discountAmount, form.goodsFee.discountAmount)) }}
                  </en-form-item>
                  <en-form-item label="抹零金额" class="col-start-1">{{ formatMoney(form.serviceFee.autoEraseAmount) }}</en-form-item>
                  <en-form-item label="工单积分">{{ formatMoney(form.serviceFee.memberAmount, 0) }}</en-form-item>
                </en-form>

                <en-form class="grid grid-cols-3 gap-x-6">
                  <en-form-item label="工单减免">
                    <template #label>
                      <div class="flex justify-between">
                        <span>工单减免</span>
                        <span class="text-xs text-primary"> 允许减免的最大金额：{{ formatMoney(store.user.discountLine) }} </span>
                      </div>
                    </template>
                    <en-input-number
                      v-model="form.data.discount"
                      :max="store.user.discountLine"
                      :disabled="form.disabled || Boolean(form.document?.id)"
                      @change="form.discount.change"
                      class="w-full"
                    ></en-input-number>
                  </en-form-item>
                  <en-form-item label="结算方式">
                    <select-maintain
                      v-model="form.data.settlementMethod"
                      :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['SVCSTLMTD']) }"
                      :props="{ label: 'name', value: 'name' }"
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                </en-form>

                <en-form label-position="right" label-width="120" label-suffix="：" class="pt-6 bg-gray-100 grid grid-cols-5">
                  <en-form-item label="应收款">{{ formatMoney(form.serviceFee.receivableAmount) }}</en-form-item>
                  <en-form-item label="索赔返修">{{ formatMoney(form.serviceFee.repareAmount) }}</en-form-item>
                  <en-form-item label="优惠减免">{{ formatMoney(form.serviceFee.receivableBadDebt) }}</en-form-item>
                  <en-form-item label="已收款">{{ formatMoney(form.serviceFee.receivedAmount) }}</en-form-item>
                </en-form>

                <en-form class="grid grid-cols-3 gap-x-6">
                  <en-form-item label="出厂时间">
                    <en-date-picker v-model="form.data.outDatetime" :disabled="form.data.status?.code === 'PD'" class="w-full"></en-date-picker>
                  </en-form-item>
                  <en-form-item label="结算时间">
                    <en-date-picker
                      v-model="form.data.settlementDatetime"
                      :disabled="form.data.status?.code === 'PD'"
                      class="w-full"
                    ></en-date-picker>
                  </en-form-item>
                  <en-form-item label="余额抵扣">
                    <template #label>
                      <div class="flex justify-between">
                        <span>余额抵扣</span>
                        <span class="text-xs text-primary"> 余额：{{ formatMoney(form.data.customer?.accountInfo.chargeable) }} </span>
                      </div>
                    </template>
                    <en-input-number
                      v-model="form.data.chargeableAmount"
                      :min="0"
                      :max="form.data.status?.code === 'PD' ? Infinity : form.data.customer?.accountInfo.chargeable"
                      :disabled="form.data.status?.code === 'PD' || !store.accessRightsHash.CHARGEABLE_DEDUCTION"
                      class="w-full"
                    ></en-input-number>
                  </en-form-item>
                  <en-form-item label="定金抵扣">
                    <template #label>
                      <div class="flex justify-between">
                        <span>定金抵扣</span>
                        <span class="text-xs text-primary"> 定金金额：{{ formatMoney(form.data.customer?.accountInfo.receiptAdvance) }} </span>
                      </div>
                    </template>
                    <en-input-number
                      v-model="form.data.receiptAdvanceAmount"
                      :min="0"
                      :max="form.data.status?.code === 'PD' ? Infinity : form.data.customer?.accountInfo.receiptAdvance"
                      :disabled="form.data.status?.code === 'PD' || !store.accessRightsHash.CHARGEABLE_DEDUCTION"
                      class="w-full"
                    ></en-input-number>
                  </en-form-item>
                  <en-form-item label="结算备注">
                    <en-input
                      v-model="form.data.settlementComment"
                      type="textarea"
                      maxlength="300"
                      :disabled="form.data.status?.code === 'PD' || !store.accessRightsHash.SERVICE_PAID_EDIT"
                    ></en-input>
                  </en-form-item>
                  <en-form-item label="审核备注">
                    <en-input
                      v-model="form.data.settlementAuditComment"
                      type="textarea"
                      maxlength="300"
                      :disabled="form.data.status?.code === 'PD' || !store.accessRightsHash.SERVICE_PAID_EDIT"
                    ></en-input>
                  </en-form-item>
                </en-form>
              </en-scrollbar>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>

    <div class="absolute right-0 top-0 w-28 h-28">
      <img v-if="form.data.status?.code === 'PD'" src="@service/assets/icon-pd.png" class="w-full h-full" />
      <img v-if="form.data.status?.code === 'DC'" src="@service/assets/icon-dc.png" class="w-full h-full" />
    </div>
  </service-layout>

  <workorder-dialog v-model="dialog.visible"></workorder-dialog>

  <workorder-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></workorder-detail>

  <en-dialog v-model="spraySurface.visible" title="喷涂钣面" center width="1600" destroy-on-close>
    <spray-surface-selection v-model="spraySurface.data.codes" @change="spraySurface.codes.change"></spray-surface-selection>

    <div class="mt-4">
      <en-table :data="spraySurface.data.maintenances">
        <en-table-column type="index" label="序号" width="60"></en-table-column>
        <en-table-column label="项目名称" prop="maintenance.name">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
            <select-maintain
              v-if="!row.maintenance?.id || row.maintenance.spraySurface?.startsWith('OTH')"
              v-model="row.maintenance"
              :ajax="{ action: 'GET /enocloud/common/maintenance', params: (params) => (params.payload = { spraySurface: 'OTH' }) }"
              :props="{ label: 'name', value: '' }"
              value-key="id"
              class="w-full"
              @change="spraySurface.maintenances.change(row)"
            ></select-maintain>
            <span v-else>{{ row.maintenance?.name }}</span>
          </template>
        </en-table-column>
        <en-table-column label="面积">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
            <en-input-number
              v-if="row.maintenance?.spraySurface?.startsWith('OTH')"
              :model-value="row.count * (row.maintenance?.square ?? 0)"
              step-strictly
              controls-position="right"
              :min="0.2"
              :step="0.2"
              class="w-full"
              @change="spraySurface.maintenances.count.change(row, $event)"
            ></en-input-number>
            <span v-else>{{ formatNumber(row.count * (row.maintenance?.square ?? 0), 1) }}m²</span>
          </template>
        </en-table-column>
        <en-table-column label="以诺行标准结算价" prop="" width="150"></en-table-column>
        <en-table-column label="是否质保" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
            <select-maintain
              v-model="row.warrantied"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
              :props="{ label: 'message', value: '' }"
              :disabled="row.maintenance?.spraySurface && ['S', 'B'].includes(row.maintenance.spraySurface.split('_')[1])"
              value-key="code"
              class="w-full"
            ></select-maintain>
          </template>
        </en-table-column>
        <en-table-column label="备注" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
            <en-input v-model="row.comment"></en-input>
          </template>
        </en-table-column>
        <en-table-column label="工时" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
            <en-input-number
              v-model="row.laborHour"
              :disabled="store.attribute.FCMTNCS.value === 'Y' || store.attribute.SVMTVLM.value === 'P'"
              class="w-full"
            ></en-input-number>
          </template>
        </en-table-column>
        <en-table-column label="内部工时" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
            <en-input-number
              v-model="row.internalLaborHour"
              :disabled="store.attribute.FCMTNCS.value === 'Y' || store.attribute.SVMTVLM.value === 'P'"
              class="w-full"
            ></en-input-number>
          </template>
        </en-table-column>
        <en-table-column label="单价" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
            <en-input-number v-model="row.price" :precision="2" :disabled="store.attribute.FCMTNCS.value === 'Y'" class="w-full"></en-input-number>
          </template>
        </en-table-column>
        <en-table-column label="操作" prop="">
          <template #default="{ $index }: { $index: number }">
            <en-button link type="primary" @click="spraySurface.maintenances.delete.click($index)">删除</en-button>
          </template>
        </en-table-column>
      </en-table>

      <en-button text type="primary" @click="spraySurface.maintenances.add.click">特殊喷漆</en-button>
    </div>

    <template #footer>
      <en-button @click="spraySurface.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="spraySurface.footer.confirm.click">确定</en-button>
    </template>
  </en-dialog>

  <charging-method-batch v-model="chargingMethodBatch.visible" @confirm="chargingMethodBatch.confirm"></charging-method-batch>

  <working-assignee-batch
    v-model="workingAssigneeBatch.visible"
    :branch-id="form.data.branch?.id"
    @confirm="workingAssigneeBatch.confirm"
  ></working-assignee-batch>

  <goods-selection v-model="goodsSelect.visible" type="service" @confirm="goodsSelect.confirm"></goods-selection>

  <maintenance-edit
    v-model="maintenanceEdit.visible"
    v-model:data="maintenanceEdit.form.data"
    :branchId="form.data.branch?.id"
    :documentApplication="form.document"
    :disabled="form.disabled"
    :customer="form.data.customer"
    :hasFullCarPaints="form.hasFullCarPaints"
  ></maintenance-edit>

  <maintenance-rework v-model="rework.visible" :data="rework.data" @confirm="rework.confirm"></maintenance-rework>

  <maintenance-reworks v-model="reworks.visible" :data="reworks.data" @confirm="reworks.confirm"></maintenance-reworks>

  <workorder-discount
    v-model="discount.visible"
    :type="discount.type"
    :maintenances="discount.maintenances"
    :goods="discount.goods"
    @confirm="discount.confirm"
  ></workorder-discount>

  <workorder-rework-order v-model="reworkOrder.visible" :vehicle-id="form.data.vehicle?.id" @select="reworkOrder.select"></workorder-rework-order>

  <workorder-rework-maintenance
    v-model="reworkMaintenance.visible"
    :service="form.data"
    :data="reworkMaintenance.data"
    @confirm="reworkMaintenance.confirm"
  ></workorder-rework-maintenance>

  <workorder-logs v-model="logs.visible" :data="form.data"></workorder-logs>

  <workorder-maintain
    v-model="maintain.visible"
    :vin="form.data.vehicle?.vin"
    :mileage="form.data.currentMileage?.toString()"
    @confirm="maintain.confirm"
  ></workorder-maintain>

  <workorder-fee-management v-model="management.visible" :data="form.data" @confirm="form.get"></workorder-fee-management>

  <workorder-fee-tax
    v-model="tax.visible"
    :data="form.data"
    :amountBeforeAutoEraseAmount="form.serviceFee.amountBeforeAutoEraseAmount"
    @confirm="form.get"
  ></workorder-fee-tax>

  <workorder-fee-other v-model="other.visible" :data="form.data" @confirm="form.get"></workorder-fee-other>

  <workorder-fee-expense v-model="expense.visible" :data="form.data" @confirm="form.get"></workorder-fee-expense>

  <send-coupon v-model="coupon.visible" :options="[form.data.customer]" @confirm="form.get"></send-coupon>

  <top-up v-model="topup.visible" :customer="form.data.customer" @confirm="form.get"></top-up>

  <deposit v-model="deposit.visible" :customer="form.data.customer" @confirm="form.get"></deposit>

  <blance v-model="blance.visible" :customer="form.data.customer" @confirm="form.get"></blance>

  <mall-order-purchase v-model="mall.visible" :customer="form.data.customer" @confirm="form.get"></mall-order-purchase>

  <customer-record
    v-model="customerRecord.visible"
    :customer-data="form.data.customer"
    :vehicle-data="form.data.vehicle"
    @confirm="form.get"
  ></customer-record>

  <vehicle-record v-model="vehicleRecord.visible" :data="form.data.vehicle" @confirm="form.get"></vehicle-record>

  <workorder-outsource v-model="outsource.visible" v-model:data="outsource.data" @confirm="outsource.confirm"></workorder-outsource>

  <workorder-discount-approval
    v-model="approval.visible"
    :data="form.data"
    :maintenance-fee="form.maintenanceFee"
    :goods-fee="form.goodsFee"
    :service-fee="form.serviceFee"
    @confirm="form.get"
    @cancel="form.get"
  ></workorder-discount-approval>
</template>

<script lang="ts">
import { workorder } from '@service/common/workorder'

import { Edit } from '@element-plus/icons-vue'

import Blance from '@service/components/blance.vue'
import ChargingMethodBatch from '@service/components/charging-method-batch.vue'
import Deposit from '@service/components/deposit.vue'
import WorkorderReworkOrder from '@service/components/workorder-rework-order.vue'
import WorkorderReworkMaintenance from '@service/components/workorder-rework-maintenance.vue'
import ServiceLayout from '@service/components/service-layout.vue'
import TopUp from '@service/components/top-up.vue'
import MaintenanceEdit from '@service/components/maintenance-edit.vue'
import MaintenanceRework from '@service/components/maintenance-rework.vue'
import MaintenanceReworks from '@service/components/maintenance-reworks.vue'
import WorkingAssigneeBatch from '@service/components/working-assignee-batch.vue'
import WorkorderDiscount from '@service/components/workorder-discount.vue'
import WorkorderLogs from '@service/components/workorder-logs.vue'
import WorkorderMaintain from '@service/components/workorder-maintain.vue'
import WorkorderFeeManagement from '@service/components/workorder-fee-management.vue'
import WorkorderFeeTax from '@service/components/workorder-fee-tax.vue'
import WorkorderFeeOther from '@service/components/workorder-fee-other.vue'
import WorkorderFeeExpense from '@service/components/workorder-fee-expense.vue'
import WorkorderDialog from '@service/components/workorder-dialog.vue'
import WorkorderDetail from '@service/components/workorder-detail.vue'
import WorkorderOutsource from '@service/components/workorder-outsource.vue'
import WorkorderDiscountApproval from '@service/components/workorder-discount-approval.vue'
import DamageIdentify from '@service/components/damage-identify.vue'

import { CustomerRecord, VehicleRecord, GoodsSelection, SpraySurfaceSelection, SendCoupon, MallOrderPurchase } from '@enocloud/business'

export default workorder({
  components: {
    ServiceLayout,
    SendCoupon,
    GoodsSelection,
    DamageIdentify,
    IconEdit: Edit,
    WorkorderDetail,
    MaintenanceEdit,
    MaintenanceRework,
    MaintenanceReworks,
    WorkorderDialog,
    ChargingMethodBatch,
    WorkingAssigneeBatch,
    SpraySurfaceSelection,
    WorkorderDiscount,
    WorkorderReworkOrder,
    WorkorderReworkMaintenance,
    WorkorderLogs,
    WorkorderMaintain,
    WorkorderFeeManagement,
    WorkorderFeeTax,
    WorkorderFeeOther,
    WorkorderFeeExpense,
    TopUp,
    Deposit,
    Blance,
    MallOrderPurchase,
    CustomerRecord,
    VehicleRecord,
    WorkorderOutsource,
    WorkorderDiscountApproval
  }
})
</script>
